.testimonial-section {
  background-color: #f8f9fa; /* Light grey background */
  padding: 50px 0;
}

.testimonial-title-black {
  color: #333;
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.testimonial-colorful-heading {
  color: #b02cd6;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.testimonial-description {
  color: #666;
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 40px;
}

.testimonial-review-slide {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 10px;
  width: 95% !important;
}

.testimonial-client-name {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.testimonial-rating {
  color: #ffb400;
  font-size: 1rem;
  margin-bottom: 10px;
}

.testimonial-review {
  font-size: 1rem;
  color: #444;
  line-height: 1.5;
}

.slick-prev:before, .slick-next:before{
  color: pink!important;
}

/* Responsive styles */
@media (max-width: 768px) {
  .testimonial-review-slide {
    padding: 15px;
  }

  .testimonial-colorful-heading {
    font-size: 2rem;
  }

  .testimonial-description {
    font-size: 1rem;
  }
}
