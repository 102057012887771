.contact-form {
  padding: 2rem;
  box-shadow: 0px 0px 8px 0px white;
  border-radius: 20px;
}

.social-media p {
    margin-top: 2rem;
  }
  
  .social-icons {
    display: flex;
    margin-top: 0.5rem;
  }
  
  .social-icons a {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background: linear-gradient(to right, #f92c8b 0, #b02cd6 100%);
    color: #fff;
    text-align: center;
    line-height: 35px;
    margin-right: 0.5rem;
    transition: 0.3s;
  }
  
  .social-icons a:hover {
    transform: scale(1.05);
  }
  
  


  
.information {
    display: flex;
    color: white;
    font-size: 0.95rem;
  }
  
  .information i {
    color: #f92c8b;
  }
  
  .icon {
    width: 28px;
    margin-right: 0.7rem;
  }
  
  @media only screen and (max-width:768px) {
    .contact-details{
      margin-bottom: 2rem;
    }
    
  }