#counts {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.count-box {
  width: 190px;
  height: 190px;
  border-radius: 20px;
  background: #141e30;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4); /* Soft shadow */
  transition: transform 0.4s, box-shadow 0.4s, background 0.4s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  margin: 15px;
  color: #fff;
}

.count-box i {
  font-size: 48px;
  margin-bottom: 15px;
  transition: color 0.4s;
}

.count-box .purecounter {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 10px;
}

.count-box p {
  font-size: 14px;
  font-weight: 600;
}

.count-box:hover {
  transform: translateY(-15px) scale(1.1); /* Elevates and enlarges on hover */
  box-shadow: 0 15px 40px rgba(255, 64, 129, 0.6); /* Vibrant shadow */
  background: linear-gradient(
    145deg,
    #f92c8b,
    #b02cd6
  ); /* Color change on hover */
}

.count-box-1 {
  background: linear-gradient(145deg, #f92c8b, #b02cd6);
}

.count-box-2 {
  background: linear-gradient(145deg, #f92c8b, #b02cd6);
}

.count-box-3 {
  background: linear-gradient(145deg, #f92c8b, #b02cd6);
}

.count-box-4 {
  background: linear-gradient(145deg, #f92c8b, #b02cd6);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.count-box {
  animation: fadeInUp 0.6s ease forwards;
}

#em1,
#em2,
#em3,
#em4 {
  animation: rotateIcon 2s infinite;
}

@keyframes rotateIcon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 768px) {
  .count-box{
    border-radius: 0;
    width: 100%;
    height: 90%;
    margin: 0;
  }
}
