.navbar {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background-color: white;
  transition: all 0.35s ease;
  border-bottom: 1px solid white;
  /* padding: 2rem 2rem; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4.25rem;
}

.navbar-nav {
  gap: 12px;
}

.navbar.on-scroll {
  background: var(--color-black-300);
  box-shadow: var(--shadow-medium);
}

.nav-link {
  font-size: 16px;
  font-weight: 500;
  color: black;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.callbutton {
  background: linear-gradient(to right, #f92c8b 0, #b02cd6 100%);
  padding: 5px;
  border-radius: 8px;
  color: white;
  font-weight: 600;
  font-size: 18px;
  text-decoration: none;
}

.service {
  text-align: center;
}

.service img {
  height: 100px;
  width: auto;
  border-radius: 5px;
  display: block;
  text-align: center;
}

.dropMain {
  position: absolute;
  left: 0;
  right: 0;
  max-height: 0;
  transition: all 300ms ease-in;
  overflow: hidden;
  opacity: 0;
}

.webdesign.active .dropMain {
  max-height: max-content;
  opacity: 1;
}



.navbar-toggler {
  background: linear-gradient(
    to right,
    #673ab7 0,
    #e91e63 36%,
    #e91e63 65%,
    #673ab7 100%
  );
}

.nav-tabs {
  border-bottom: 0;
}

.navbar-toggler-icon {
  color: white;
  display: contents;
}

.service a {
  color: black;
  display: ruby;
}

.webdesign {
  color: black;
}

@media only screen and (max-width: 768px) {
  .navbar-collapse {
    background-color: white;
    padding: 1rem 1rem;
    width: 100%;
  }

  .service img {
    height: 100%;
    width: 50px;
    font-size: 14px;
  }

  .webdesign img {
    height: 100%;
    width: 50px;
  }
}


/* Fixing the logo size */
.navbar-brand img {
  width: 250px !important; /* Set a fixed width for the logo */
  height: auto; /* Maintain aspect ratio */
}

@media (max-width: 768px) {
  .navbar-brand img {
    width: 100px; /* Slightly smaller for mobile */
  }
}

/* Ensuring text color is white */
.nav-link {
  font-size: 16px;
  font-weight: 500;
  color: black !important; /* Make text white */
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.callbutton {
  color: white !important; /* Ensure button text remains white */
}
